
/* forms & inputs --------------------------------------------------------------------------------------------------- */


select {
    color: var(--color-gray-400);
}

select.active {
    color: var(--color-gray-900);
}

.field .icon input:not([type=checkbox]) {
    padding-right: 2.125rem;
}

.field .error input,
.field .error textarea {
    color: var(--color-red-600);
}

input[type=checkbox] {
    margin: 0.1875rem 1rem 0 0;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    display: none;
}

.field .error input,
.field .error textarea,
div.error.field select,
.field.error .react-datepicker__input-container {
    border-color: var(--color-red-500) !important;
}

.field .error input::placeholder,
.field .error textarea::placeholder {
    color: var(--color-red-500);
}

input::placeholder  {
    color: var(--color-gray-400);
}

.ui.icon.input>.icon {
    display: flex;
    width: 1.375rem;
    right: 1.75rem;
    align-self: center;
    color: var(--color-primary-500);
    height: calc(100% - 0.25rem);
}

.ui.icon.input.password>.icon {
    position: absolute;
    right: 0.5rem;
}

.ui.icon.input input[type=password] + .svg, .ui.icon.input.password-input input + .svg {
    position: absolute;
    right: 0.5rem;
}

.ui.toggle.checkbox label {
    font-size: 0.875rem;
    font-weight: 500;
    margin-left: 4rem;
    color: var(--color-gray-500);
}

.ui.toggle.checkbox input:checked~label {
    color: var(--color-gray-900);
}

.ui.toggle.checkbox label:before {
    display: block;
    position: absolute;
    content: "";
    -webkit-transform: none;
    transform: none;
    border: none;
    top: 0;
    background: rgba(0,0,0,.05);
    box-shadow: none;
    width: 3.5rem;
    height: 1.5rem;
    border-radius: 500rem;
}

.ui.checkbox {
    position: relative;
    display: inline-block;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    outline: 0;
    vertical-align: baseline;
    font-style: normal;
    min-height: 1.125rem;
    min-width: 1.125rem;
}

.ui.checkbox label {
    display: block;
}

.ui.checkbox input:focus-visible + label:before {
    outline: 0.188rem solid var(--color-yellow-400);
    outline-offset: 0;
}

.ui.checkbox input[type=checkbox] {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    outline: 0;
    width: 17px;
    height: 17px;
}

.ui.checkbox input.hidden+label {
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.ui.checkbox input:disabled+label {
    cursor: auto;
}

.ui.toggle.checkbox label:after {
    background: #fff linear-gradient(transparent,rgba(0,0,0,.05));
    position: absolute;
    content: ""!important;
    opacity: 1;
    border: none;
    box-shadow: 0 1px 2px 0 rgb(34 36 38 / 15%), inset 0 0 0 1px rgb(34 36 38 / 15%);
    width: 1.5rem;
    height: 1.5rem;
    top: 0;
    left: 0;
    border-radius: 500rem;
    transition: background .3s ease,left .3s ease;
}

.ui.toggle.checkbox input:checked~label:after {
    left: 2.15rem;
    box-shadow: 0 1px 2px 0 rgb(34 36 38 / 15%), inset 0 0 0 1px rgb(34 36 38 / 15%);
}

.ui.checkbox:not(.toggle):not(.radio) label:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 1rem;
    height: 1rem;
    opacity: 1;
    border: 0.0625rem solid var(--color-gray-300);
    border-radius: 0.25rem;
    content: "";
}

.ui.checkbox.error:not(.toggle):not(.radio) label:before {
    border: 0.0625rem solid var(--color-red-600);
}

.ui.checkbox.error:not(.toggle):not(.radio) label {
    color: var(--color-red-600);
}

.ui.checkbox:not(.toggle):not(.radio) label {
    padding-left: 1.5rem;
}

.ui.checkbox.checked:not(.toggle):not(.radio) label:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 0.875rem;
    height: 1rem;
    padding-left: 0.125rem;
    opacity: 1;
    border: 0.0625rem solid var(--color-gray-300);
    border-radius: 0.25rem;
    background-color: var(--color-gray-700);
    color: var(--color-white);
    content: url("data:image/svg+xml,%3Csvg width='12' height='10' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.76485 0.205009C7.90935 0.341618 7.99368 0.530023 7.99931 0.728798C8.00494 0.927573 7.93139 1.12045 7.79485 1.26501L3.54485 5.76501C3.47597 5.83781 3.39319 5.89607 3.30142 5.93634C3.20964 5.97661 3.11073 5.99808 3.01051 5.99948C2.9103 6.00088 2.81082 5.98218 2.71796 5.94448C2.6251 5.90679 2.54073 5.85086 2.46985 5.78001L0.219847 3.53001C0.0873665 3.38783 0.0152433 3.19979 0.0186715 3.00549C0.0220998 2.81119 0.100812 2.6258 0.238225 2.48839C0.375638 2.35097 0.561023 2.27226 0.755324 2.26883C0.949625 2.26541 1.13767 2.33753 1.27985 2.47001L2.98485 4.17401L6.70485 0.235009C6.84146 0.0905061 7.02986 0.00617039 7.22863 0.000544684C7.42741 -0.00508102 7.62028 0.0684638 7.76485 0.205009Z' fill='white'/%3E%3C/svg%3E%0A");
}

.ui.checked.disabled.checkbox label:after {
    background-color: var(--color-gray-300) !important;
}

.ui.disabled.checkbox label{
    color: var(--color-gray-500);
}

.ui.disabled.checkbox:not(.checked):not(.toggle):not(.radio) label:before{
    background-color: var(--color-gray-300);
}

.ui.radio.checkbox label {
    padding-left: 1.25rem !important;
}

.ui.radio.checkbox label:before {
    position: absolute;
    top: 0.0625rem;
    left: 0;
    width: 0.8125rem;
    height: 0.8125rem;
    border: 0.0625rem solid var(--color-gray-300);
    border-radius: 500rem;
    content: '';
    transition: border .1s ease,opacity .1s ease,transform .1s ease,box-shadow .1s ease;
}
.ui.radio.checkbox input:checked~label:before {
    background-color: var(--color-gray-700);
    border-color: var(--color-gray-700);
}

.ui.radio.checkbox label:after {
    position: absolute;
    top: 0.0625rem;
    left: 0;
    width: 0.9375rem;
    height: 0.9375rem;
    border-radius: 500rem;
    content: '';
    transition: border .1s ease,opacity .1s ease,transform .1s ease,box-shadow .1s ease;
}
.ui.radio.checkbox input:checked~label:after {
    background-color: var(--color-white);
    transform: scale(.35);
}
.ui.radio.checkbox input:disabled~label:after {
    background-color: var(--color-gray-300);
    transform: scale(.85);
}

/* progress bar ----------------------------------------------------------------------------------------------------- */

.ui.progress {
    background: var(--color-white);
    border: 1px solid #ebebeb;
    box-shadow: 0 0 0 100px #fbfbfb inset;
    border-radius: 3px;
    height: 100%;
    width: 100%;
}

.ui.progress .bar {
    border-radius: 2px;
    height: 100%;
}

.ui.progress.notready .bar {
    background: var(--color-red-600);
}

.ui.progress.ready .bar {
    background: var(--color-green-700);
}


/* anchors/links ------------------------------------------------------------------------------------------------------------ */

a {
    display: block;
    color: var(--color-primary-500);
    text-decoration: underline;
}

a:hover {
    cursor: pointer;
}

/* datepicker ------------------------------------------------------------------------------------------------------- */

.react-datepicker__header,
.react-datepicker__month,
.react-datepicker__current-month,
.react-datepicker__header__dropdown.react-datepicker__header__dropdown--scroll,
.react-datepicker__year-dropdown {
    display: block;
}

.react-datepicker-popper {
    z-index: 1002;
}

.react-datepicker-popper[data-placement^=bottom] {
    padding-top: 0.25rem !important;
}

.react-datepicker-popper[data-placement^=top] {
    padding-bottom: 0.25rem !important;
}

.react-datepicker {
    background: var(--color-white);
    box-shadow: 0 0.625rem 1rem -0.1875rem rgba(0, 0, 0, 0.1), 0 0.25rem 0.375rem -0.125rem rgba(0, 0, 0, 0.05);
    border-radius: 0.375rem !important;
    width: 16.55rem;
    border-color: var(--color-gray-300) !important;
}

.react-datepicker__calendar-icon {
    position: absolute !important;
    width: 1.25rem !important;
    height: 1.25rem !important;
    color: var(--color-gray-400);
    right: 0.05rem;
}

.react-datepicker__calendar-icon.disabled {
    color: var(--color-gray-400);
}

.react-datepicker__input-container {
    border: 1px solid var(--color-gray-300);
    border-radius: 0.375rem;
}

.react-datepicker__view-calendar-icon input {
    padding: 0.5rem 0.8125rem !important;
}

.react-datepicker__triangle,
.react-datepicker__close-icon {
    display: none !important;
}

.react-datepicker__month-container {
    flex-direction: column;
    width: 16.55rem;
    align-items: center;
}

.react-datepicker__header {
    background-color: var(--color-white) !important;
    border-bottom: none !important;
}

.react-datepicker__current-month--hasYearDropdown {
    font-size: 0.875rem !important;
    font-weight: 500 !important;
    font-family: 'Roboto', sans-serif;
}

.react-datepicker__day-name {
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
    width: 1.85rem !important;
}

.react-datepicker__day {
    display: flex !important;
    font-weight: 400 !important;
    font-size: 1rem;
    font-family: 'Roboto', sans-serif;
    transition: all .2s;
    height: 2.167rem !important;
    width: 2.167rem !important;
    margin: 0 !important;
    border-radius: .375rem !important;
    justify-content: center;
    align-items: center;
}

.react-datepicker__day:hover {
    background-color: var(--color-gray-200) !important;
}

.react-datepicker__day--today,
.react-datepicker__day--keyboard-selected {
    background-color: var(--color-gray-300) !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--selected:hover {
    background-color: var(--color-gray-900) !important;
    color: var(--color-white) !important;
}

.react-datepicker__navigation--next {
    content: url("data:image/svg+xml,%3Csvg viewBox='0 0 8 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.7 0 .3 1.4 4.9 6 .3 10.6 1.7 12l6-6z' fill='currentColor'/%3E%3C/svg%3E") !important;
    color: var(--color-gray-700);
    height: 0.75rem !important;
    border: none !important;
    margin-top: 0.6rem;
    margin-right: 2rem;
    box-shadow: none;

}

.react-datepicker__navigation--previous {
    content: url("data:image/svg+xml,%3Csvg viewBox='0 0 8 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.7 1.4 6.3 0l-6 6 6 6 1.4-1.4L3.1 6z' fill='currentColor'/%3E%3C/svg%3E") !important;
    color: var(--color-gray-700);
    height: 0.75rem !important;
    border: none !important;
    margin-top: 0.6rem !important;
    margin-left: 2rem !important;
    box-shadow: none;
}

input.datepicker {
    border: none;
}

input.datepicker:disabled {
    background-color: var(--color-gray-50);
    color: var(--color-gray-400);
}

.react-datepicker__header__dropdown {
    font-weight: 400;
    font-size: 1rem;
    font-family: 'Roboto', sans-serif;
    margin: 0.5rem 0;
}

.react-datepicker__year-read-view {
    display: flex;
    align-items: center;
}

.react-datepicker__year-read-view--down-arrow {
    border: none !important;
    content: url("data:image/svg+xml,%3Csvg role='img' width='100%25' height='100%25' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.825 0.912598L5 4.72926L1.175 0.912598L0 2.0876L5 7.0876L10 2.0876L8.825 0.912598Z' fill='currentColor'/%3E%3C/svg%3E") !important;
    transform: none !important;
    width: 0.625rem !important;
    top: unset !important;
}

.react-datepicker__year-dropdown {
    background-color: var(--color-white) !important;
    box-shadow: 0 0.625rem 1rem -0.1875rem rgba(0, 0, 0, 0.1), 0 0.25rem 0.375rem -0.125rem rgba(0, 0, 0, 0.05);
    border-radius: 0.375rem !important;
    border-color: var(--color-gray-300) !important;
}

.react-datepicker__year-option:first-of-type:has(.react-datepicker__navigation--years-upcoming),
.react-datepicker__year-option:last-of-type:has(.react-datepicker__navigation--years-previous) {
    height: 2rem !important;
}

.react-datepicker__navigation--years-upcoming {
    top: 0.5rem !important;
    content: url("data:image/svg+xml,%3Csvg role='img' width='100%25' height='100%25' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.59 7.58536L6 2.99536L1.41 7.58536L1.2414e-07 6.16536L6 0.165357L12 6.16536L10.59 7.58536Z' fill='currentColor'/%3E%3C/svg%3E") !important;
    width: 0.75rem !important;
    height: 0.75rem !important;
}

.react-datepicker__navigation--years-previous {
    top: 0.75rem !important;
    content: url("data:image/svg+xml,%3Csvg role='img' width='100%25' height='100%25' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.825 0.912598L5 4.72926L1.175 0.912598L0 2.0876L5 7.0876L10 2.0876L8.825 0.912598Z' fill='currentColor'/%3E%3C/svg%3E") !important;
    width: 0.75rem !important;
    height: 0.75rem !important;
}

.react-datepicker__year-option--selected {
    display: none;
}

.react-datepicker__year-option--selected_year {
    background-color: var(--color-gray-900) !important;
    color: var(--color-white) !important;
}

/* buttons ---------------------------------------------------------------------------------------------------------- */

button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 0.5rem 1rem;
    border: 2px solid var(--color-primary-500);
    border-radius: 0.375rem;
    box-sizing: border-box;
    box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.05);
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    height: 2.375rem;
    margin-left: 0.5rem;
    white-space: nowrap;
}

button:first-of-type {
    margin-left: 0;
}

button.primary {
    background-color: var(--color-primary-500);
    color: var(--color-white);
}

button.primary:hover {
    background-color: var(--color-primary-600);
}

button.primary.disabled {
    background-color: var(--color-gray-400);
    border-color: var(--color-gray-400);
    cursor: unset;
}

button.secondary {
    background-color: var(--color-white);
    color: var(--color-primary-500);
}

button.secondary.disabled {
    background-color: var(--color-gray-100);
    border-color: var(--color-gray-300);
    color: var(--color-gray-500);
    cursor: unset;
}

button.secondary:hover {
    background-color: var(--color-gray-100);
}

button.tertiary {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    cursor: pointer;
    padding: 0;
    box-shadow: none;
    border: none;
    background-color: transparent;
    color: var(--color-primary-500);
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    text-decoration: underline;
}

button.quaternary {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    cursor: pointer;
    padding: 0;
    box-shadow: none;
    border: none;
    background-color: transparent;
    color: var(--color-primary-500);
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    text-decoration: underline;
}

button.tertiary.nolink {
    text-decoration: none;
    color: unset;
    cursor: unset;
}

button.tertiary:hover {
    color: var(--color-primary-600);
}

button.tertiary.disabled {
    color: var(--color-gray-400);
    cursor: unset;
}

button.tertiary.disabled svg {
    color: var(--color-gray-400);
    cursor: unset;
}

.buttons {
    display: flex;
    flex-direction: row;
}

.buttons .button {
    border-radius: 0;
    margin: 0;
    background: var(--color-white);
    border: 1px solid var(--color-gray-300);
    border-right: none;
    color: var(--color-primary-500);
}

.ui.buttons .div {
    border-radius: 0;
    margin: 0;
    background: var(--color-white);
    border: 1px solid var(--color-gray-300);
    border-right: none;
    color: var(--color-primary-500);
}

.buttons .button.active {
    background: var(--color-gray-200);
    color: var(--color-gray-900);
    cursor: default;
}

.buttons .button:hover {
    background: var(--color-gray-200);
}

.buttons .button:first-of-type {
    border-radius: 0.375rem 0 0 0.375rem;
}

.buttons .button:last-of-type {
    border-right: 1px solid var(--color-gray-300);
    border-radius: 0 0.375rem 0.375rem 0;
}

.buttons .button:only-child {
    border-radius: 0.375rem;
}

.buttons .disabled {
    background: var(--color-gray-100);
    border-color: var(--color-gray-300);
    color: var(--color-gray-500);
    cursor: default;
}

.buttons .disabled:hover {
    background: var(--color-gray-100);
    border-color: var(--color-gray-300);
}

.buttons .disabled.active {
    background: var(--color-gray-200);
    border-color: var(--color-gray-300);
    color: var(--color-gray-900);
    cursor: default;
}

.buttons .disabled.active:hover {
    background: var(--color-gray-200);
    border-color: var(--color-gray-300);
}

.button .button_icon {
    height: 1.25rem;
}


/* tables ----------------------------------------------------------------------------------------------------------- */

table {
    border-collapse: collapse;
    width: 100%;
    min-width: 1px;
}

.table__wrapper {
    display: inline-grid;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
}

thead {
    background-color: var(--color-gray-50);
}

thead tr {
    height: 2.5rem;
    border-bottom: 1px solid var(--color-gray-200);
}

th {
    text-align: left;
    font-weight: normal;
    height: 0;
}

tbody tr {
    cursor: pointer;
    height: 3.8125rem;
    border-bottom: 1px solid var(--color-gray-200);
}

tbody tr:hover {
    background-color: var(--color-gray-100);
}

td {
    padding: 0.75rem 1rem 0.75rem 1.5rem;
    text-align: left;
    white-space: normal;
}

td .text--sm-medium {
    color: var(--color-gray-900);
}

td:has(button.tertiary) {
    padding: 0;
    height: inherit;
}

td button.tertiary {
    text-decoration: unset;
    color: unset;
    border-radius: 0;
    width: 100%;
    height: inherit;
    padding: 0.75rem 1rem 0.75rem 1.5rem;
    word-wrap: break-word;
    white-space: normal;
}

td button.tertiary.disabled {
    color: unset;
}

.table__message {
    display: flex;
    flex-direction: column;
    padding-top: 4.375rem;
    padding-bottom: 4.375rem;
}

.table__message__text {
    justify-content: center;
    margin-bottom: 1rem;
    color: var(--color-gray-500);
}

.table__message__text.error {
    color: var(--color-red-600);
}

.table__message__buttons {
    justify-content: center;
}

.table__loading {
    opacity: .5;
}

/* table controls --------------------------------------------------------------------------------------------------- */

.table__controls__searchrefresh {
    align-items: center;
}

/* placeholder ------------------------------------------------------------------------------------------------------ */


.skeleton__cell {
    width: 100%;
    height: 1.25rem;
    background: #777;
    animation : shimmer 2s infinite;
    background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
    background-size: 1000px 100%;
}

@keyframes shimmer {
    0% {
        background-position: -1000px 0;
    }
    100% {
        background-position: 1000px 0;
    }
}




/* messages --------------------------------------------------------------------------------------------------------- */

.message {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    padding: 1rem;
    margin-bottom: 1rem;
}

:nth-last-child(1 of .message) {
    margin-bottom: 1.5rem;
}

.message.error {
    background-color: var(--color-red-50);
    border-left: 4px solid var(--color-red-600);
    color: var(--color-red-900);
}

.message.success {
    background-color: var(--color-green-50);
    border-left: 4px solid var(--color-green-400);
    color: var(--color-green-800);
}

.message.warning {
    background-color: var(--color-yellow-50);
    border-left: 4px solid var(--color-yellow-400);
    color: var(--color-yellow-800);
}

.message.info {
    background-color: var(--color-blue-50);
    border-left: 4px solid var(--color-blue-500);
    color: var(--color-blue-800);
}

.message .content {
    flex-direction: column;
}

.message .content p {
    display: flex;
    margin: 0;
}

.message .icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.75rem;
}

.message .svg {
    width: 1.5rem;
    flex-shrink: 0;
    margin-right: 0.75rem;
    align-self: flex-start;
}

.message.error .icon svg {
    color: var(--color-red-600);
}

.message.warning .icon svg {
    color: var(--color-yellow-400);
}

.message.info .icon svg {
    color: var(--color-blue-400);
}

.message.success .icon svg {
    color: var(--color-green-400);
}



/* modals ----------------------------------------------------------------------------------------------------------- */

body.dimmable.dimmed {
    overflow: hidden;
}

.ui.modals.inverted.dimmer {
    background-color: hsla(0,0%,0%,.65);
    opacity: 1;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal {
    z-index: 1001;
}

/* headings --------------------------------------------------------------------------------------------------------- */

h1 {
    display: flex;
    margin: 0;
}

/* focus ------------------------------------------------------------------------------------------------------------ */

:focus-visible {
    outline: 0.188rem solid var(--color-yellow-400) !important;
    outline-offset: 0;
    z-index: 0;
}


/*  MEDIA QUERIES =================================================================================================== */

@media only screen and (max-width: 53.125rem) {

    td {
        padding: 0.75rem 1.25rem;
    }

}

@media only screen and (max-width: 41rem) {

    td {
        padding: 0.75rem 0.75rem;
    }

}