.cmp_requirement .modal__content {
    row-gap: 1rem;
}

.cmp_requirement__top_message {
    display: block;
}

.cmp_requirement .missing_credential_container {
    flex-direction: column;
    width: 30.75rem;
    background-color: var('#DFE0EB');
    border: 1px solid var(--color-gray-300);
    border-radius: 0.375rem;
    box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
    padding: 1rem;
}

.cmp_requirement .missing_credential_text {
    margin-top: 1rem;
}

.cmp_requirement .btn_add_credential {
    display: flex;
}

.cmp_requirement .btn_add_credential--mobile {
    display: none;
}

.cmp_requirement .message {
    margin-top: 0;
}

.cmp_requirement .missing_credential_container .field {
    justify-content: center;
}

@media only screen and (max-width: 35.5rem) {

    .cmp_requirement .missing_credential_container {
        max-width: 100%;
    }

    .cmp_requirement .btn_add_credential {
        display: none;
    }

    .cmp_requirement .btn_add_credential--mobile {
        display: flex;
    }
}