.cmp_certificate {
    border: 1px solid var(--color-gray-300);
    flex-direction: column;
    margin: 0.75rem auto;
    max-width: 37.5rem;
    width: 100%;
    background-color: var(--color-white);
}

.card .cmp_certificate .text--sm-regular {
    color: unset;
}

.cmp_certificate.certificate__inline {
    min-width: 35rem;
}

.cmp_certificate .certificate__header {
    align-items: center;
    border-bottom: 1px solid var(--color-gray-300);
    height: 5.1875rem;
    padding: 0 1.5rem;
}

.cmp_certificate .certificate__header img {
    height: 3.1875rem;
}

.cmp_certificate .certificate__body {
    flex-direction: column;
    padding: 1.5rem 1.5rem 0 1.5rem;
}

.cmp_certificate .certificate__body .badge__status {
    align-items: center;
    border-radius: 2rem;
    border: 1px solid;
    padding: 0.25rem 0.625rem;
    margin: 0 auto 1rem 0;
    display: flex;
    flex-direction: row;
}

.cmp_certificate .certificate__body .badge__status .svg {
    height: 1.041875rem;
    margin-right: 0.5rem;
    width: 1.041875rem;
}

.cmp_certificate .certificate__body .badge__status--red {
    background-color: var(--color-red-100);
    border-color: var(--color-red-200);
    color: var(--color-red-800);
}

.cmp_certificate .certificate__body .badge__status--yellow {
    background-color: var(--color-yellow-100);
    border-color: var(--color-yellow-200);
    color: var(--color-yellow-800);
}

.cmp_certificate .certificate__body .badge__status--blue {
    background-color: var(--color-blue-100);
    border-color: var(--color-blue-200);
    color: var(--color-blue-800);
}

.cmp_certificate .certificate__body .badge__status--green {
    background-color: var(--color-green-100);
    border-color: var(--color-green-200);
    color: var(--color-green-800);
}

.cmp_certificate .certificate__body .certificate__body__name {
    margin-bottom: 2rem;
}

.cmp_certificate .certificate__body .certificate__body__holder {
    flex-direction: column;
    margin-bottom: 2rem;
}

.cmp_certificate .certificate__body .certificate__body__holder .text--sm-regular span {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
}

.cmp_certificate .certificate__link {
    cursor: pointer;
    text-decoration: underline;
}

.cmp_certificate .certificate__body .certificate__additional_fields {
    flex-direction: column;
    margin-bottom: 2rem;
}

.cmp_certificate .certificate__body .certificate__body__verification {
    flex-direction: column;
    margin-bottom: 2rem;
}

.cmp_certificate .certificate__body .certificate__body__datagroup {
    flex-direction: row;
}

.cmp_certificate .certificate__body .certificate__body__datagroup .text--sm-medium {
    min-width: 10rem;
    width: 10rem;
}

.cmp_certificate .certificate__body__proof {
    margin-bottom: 1.5rem;
    margin-right: auto;
}

.cmp_certificate .certificate__body__document {
    flex-direction: column;
    margin-bottom: 1.5rem;
}

.cmp_certificate .certificate__body__document__item .svg {
    height: 1.125rem;
    margin-bottom: 0.5rem;
    margin-right: 0.25rem;
    width: 1.125rem;
}

.cmp_certificate .certificate__body__document__item .text--sm-regular {
    text-decoration: underline;
}

.cmp_certificate .certificate__details {
    flex-direction: row;
    padding: 1.5rem 1.5rem 0 1.5rem;
}

.cmp_certificate .certificate__details__logo {
    align-items: start;
    margin-bottom: 1.5rem;
    margin-right: 1.5rem;
}

.cmp_certificate .certificate__details__logo img {
    object-fit: contain;
    width: 6.5rem;
}

.cmp_certificate .certificate__details__text {
    flex-direction: column;
}

.cmp_certificate .certificate__details__text .text--md-medium {
    margin-bottom: 0.25rem;
}

.cmp_certificate .certificate__details__text .text--sm-regular {
    margin-bottom: 1.5rem;
}

.cmp_certificate .certificate__footer {
    align-items: center;
    background-color: var(--color-gray-100);
    flex-direction: row;
}

.cmp_certificate .certificate__footer .text--xs-regular {
    color: var(--color-gray-700);
    justify-content: end;
    width: 50%;
}

.cmp_certificate .certificate__footer img {
    height: 1.5rem;
    margin: 0.625rem 0 0.625rem 0.25rem;
}

.cmp_certificate .tertiary.supporting-doc {
    height: initial;
    margin-bottom: 5px;
}

.offline .cmp_certificate .text--sm-regular ,
.offline .cmp_certificate .display--sm-medium {
    color: unset;
}

.cmp_certificate .prequalifiers_wrapper {
    padding: 2rem;
    display: flex;
    flex-direction: column;
}

.cmp_certificate .prequalifiers_title {
    color: var(--color-gray-600);
}

.cmp_certificate .prequalifier_item {
    align-items: center;
    border-top: none;
    display: flex;
    flex-direction: row;
    padding: 1.1875rem 0 1.1875rem 0;
}

.cmp_certificate .prequalifiers_wrapper > .prequalifier_item:first-child {
    padding-top: 0;
    border-top: none;
}

.cmp_certificate .prequalifier_item ~ .prequalifier_item {
    border-top: 1px solid var(--color-gray-200);
}

.cmp_certificate .prequalifier_item .prequalifiers_icon {
    width: 1.125rem;
    height: 1.125rem;
    margin-right: 0.5rem;
}

.cmp_certificate .prequalifier_item .prequalifier_text {
    flex-direction: column;
}

.cmp_certificate .prequalifier_item .prequalifier_text .badge {
    margin: 1rem 0;
}

.cmp_certificate .prequalifier_item .prequalifier_text .prequalifier_credential_detail {
    margin-bottom: 1rem;
}

.cmp_certificate .prequalifier_item .prequalifier_text .prequalifier_change_credential {
    text-decoration: underline;
    color: var(--color-primary-500);
    cursor: pointer;
}

.cmp_certificate .prequalifier_item .prequalifier_text .prequalifier_credential_detail:last-of-type {
    margin-bottom: 0;
}

.cmp_certificate .prequalifier_item .prequalifiers_actionbutton {
    margin-left: auto;
    margin-bottom: auto;
}

.cmp_certificate .prequalifier_item.credential {
    flex-direction: column;
    align-items: flex-start;
}

.cmp_certificate .prequalifier_credential_issuer_logo img {
    max-width: 5rem;
    max-height: 8rem;
}

@media only screen and (max-width: 70rem) {

    .cmp_certificate {
        border: none;
        margin: 0;
    }

}

@media only screen and (max-width: 55rem) {
    .cmp_certificate .prequalifier_item .prequalifiers_actionbutton {
        margin-top: 1.5rem;
        margin-left: 1.625rem;
    }
}

@media only screen and (max-width: 34.375rem) {

    .cmp_certificate:not(.certificate__inline) .certificate__body .certificate__body__datagroup {
        flex-direction: column;
        margin-bottom: 1rem;
    }

    .cmp_certificate:not(.certificate__inline) .certificate__details {
        flex-direction: column;
    }

    .cmp_certificate:not(.certificate__inline) .certificate__details__text {
        padding-left: 0;
        padding-top: 1.5rem;
    }

}