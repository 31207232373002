#mdl_upload .trackablecredits_total_wrapper {
    align-items: center;
    border: 1px solid var(--color-gray-300);
    border-radius: 0.375rem;
    box-sizing: border-box;
    flex-direction: row;
    padding: 0.75rem 1.5rem;
    width: 100%;
}

#mdl_upload .trackablecredits_total_icon {
    color: var(--color-yellow-500);
    height: 1.5rem;
    margin-right: 0.75rem;
    width: 1.5rem;
}

#mdl_upload .trackablecredits_total_value {
    background-color: var(--color-gray-100);
    border: 1px solid var(--color-gray-300);
    border-radius: 0.375rem;
    margin-left: auto;
    padding: 0.5625rem 0.8125rem;
}