
/*  details group --------------------------------------------------------------------------------------------------- */

.detailsgroup {
    align-items: center;
    border-top: 1px solid var(--color-gray-200);
    display: flex;
    flex-direction: row;
    padding: 1.1875rem 0 1.1875rem 0;
}

.detailsgroup__label {
    color: var(--color-gray-500);
    width: 11.25rem;
    align-self: start;
}

.detailsgroup__value {
    color: var(--color-gray-900) !important;
    width: calc(100% - 11.25rem);
}

.detailsgroup__value .message {
    margin-top: 0;
}

.detailsgroup__value button {
    text-align: left;
    overflow: hidden;
    word-break: break-word;
    align-self: stretch;
    height: unset;
}

.detailsgroup__value button.primary,
.detailsgroup__value button.secondary {
    height: 2.375rem;
}

.detailsgroup__value.checkboxes {
    flex-direction: column;
    row-gap: 0.5rem;
}

.detailsgroup__value.logo img {
    height: 4rem;
}

.detailsgroup__value.column {
    flex-direction: column;
}

.detailsgroup__value.logo img {
    height: 4rem;
}

.detailsgroup__value.truncate {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
}

.detailsgroup__description {
    color: var(--color-gray-900) !important;
    margin-bottom: 1rem;
    max-width: 33rem;
    white-space: pre-wrap;
}

.detailsgroup__instructions {
    width: 100%;
    color: var(--color-gray-500);
    margin-top: 1rem;
}

.detailsgroup__subgroup {
    max-width: 25rem;
    flex-direction: column;
    padding: 1rem;
    background-color: var(--color-gray-50);
    border: 1px solid var(--color-gray-200);
    margin-left: 11.25rem;
    margin-bottom: 1.25rem;
}

.detailsgroup__subgroup__description {
    margin-bottom: 1rem;
    color: var(--color-gray-900) !important;
}

.detailsgroup__subgroup__content__item {
    flex-direction: column;
    color: var(--color-gray-900) !important;
}

.modal__content .detailsgroup {
    align-items: start;
    border-top: none;
    padding: 0.875rem 0 0.875rem 0;
}

.create_summary {
    padding-left: 3rem;
    margin-top: -1.5rem;
}

.create_summary .detailsgroup {
    border: none;
    padding: 0.0625rem 0 0.0625rem 0;
}

/*  form field ------------------------------------------------------------------------------------------------------ */

.form {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem;
}

.form:last-of-type {
    margin-bottom: 0;
}

.field {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.field__feedback {
    margin-top: 0.25rem;
}

.field__feedback.error,
.field__feedback.error .text--sm-regular {
    color: var(--color-red-500);
}

.field__feedback .icon {
    width: 1rem;
    height: 1rem;
    color: var(--color-red-600);
    margin-right: 1rem;
}


/*  input ----------------------------------------------------------------------------------------------------------- */

.field input,
.field select,
.field .dropdown {
    align-items: center;
    background-color: var(--color-white);
    border: 1px solid var(--color-gray-300);
    border-radius: 0.375rem;
    box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
    color: var(--color-gray-900);
    display: flex;
    flex-direction: row;
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    height: 2.375rem;
    line-height: 1.5rem;
    max-width: 100%;
    width: 19.125rem;
    padding: 0.5rem 0.8125rem;
    outline: none;
}

.field .dropdown.error {
    border-color: var(--color-red-500);
    color: var(--color-red-500);
}

textarea {
    border: 1px solid var(--color-gray-300);
    border-radius: 0.375rem;
    box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
    color: var(--color-gray-900);
    display: flex;
    flex-direction: row;
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    padding: 0.5rem 0.8125rem;
    resize: none;
    outline: none;
    max-width: 25rem;
    height: 8.125rem;
}

.textarea_characters {
    margin-top: 0.25rem;
    max-width: 25rem;
    justify-content: end;
}

.field .disabled input,
.field .dropdown.disabled,
.field .disabled button {
    border-color: var(--color-gray-300);
    background-color: var(--color-gray-50);
    color: var(--color-gray-400) !important;
}

.field .icon {
    position: relative;
}

.mdl_override__container textarea {
    width: 31.25rem;
    max-width: calc(100vw - 3.5rem);
    height: 8.125rem;
}


/*  country and region dropdowns ------------------------------------------------------------------------------------ */

select {
    -webkit-appearance: none;
    -moz-appearance: window;
    appearance: none;
    border: none;
    background-image: url("data:image/svg+xml;utf8,<svg width='0.625rem' height='100%' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M8.825 0.912598L5 4.72926L1.175 0.912598L0 2.0876L5 7.0876L10 2.0876L8.825 0.912598Z' fill='currentColor'/> </svg>");
    background-repeat: no-repeat;
    background-position-y: calc(100% - 0.8125rem);
    background-position-x: calc(100% - 0.8125rem);
}

/*  dropdown -------------------------------------------------------------------------------------------------------- */

.field .dropdown {
    position: relative;
}

.field .dropdown .filtered {
    visibility: hidden;
}

.field .dropdown input {
    background: none transparent;
    border: none;
    box-shadow: none;
    left: 0;
    outline: 0;
    position: absolute;
    width: 100%;
    z-index: 2;
}

.field .dropdown .icon {
    width: 0.625rem;
    margin-left: auto;
    padding-left: 0.5rem;
}

.field .dropdown .divider.text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    max-width: calc(100vw - 1.1rem);
}


.field .dropdown .menu {
    backface-visibility: hidden;
    background: var(--color-white);
    border: 1px solid var(--color-gray-50);
    border-radius: 0.375rem;
    box-shadow: 0 0.625rem 1rem -0.1875rem rgba(0, 0, 0, 0.1), 0 0.25rem 0.375rem -0.125rem rgba(0, 0, 0, 0.05);
    cursor: pointer;
    display: none;
    margin: 0.25rem 0 0 -0.9375rem;
    max-height: 15.75rem;
    outline: 0;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0.25rem 0;
    position: absolute;
    top: 100%;
    transition: opacity .1s ease;
    -webkit-backface-visibility: hidden;
    -webkit-overflow-scrolling: touch;
    width: calc(100% + 2px);
    will-change: transform,opacity;
    z-index: 11;
}

.field .upward.dropdown .menu {
    top: auto;
    bottom: 100%;
    -webkit-box-shadow: 0 0 3px 0 rgb(0 0 0 / 8%);
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, .08);
    border-radius: 0.28571429rem 0.28571429rem 0 0;
}

.visible.transition {
    display: block !important;
    /*visibility: visible !important;*/
}

.field .dropdown .menu .item {
    white-space: normal;
    word-wrap: normal;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.171875rem;
    cursor: pointer;
    color: var(--color-gray-900);
    padding: 0.5rem 0.75rem;
}

.field .dropdown .menu .item:hover:not(.disabled) {
    background: var(--color-gray-100);
}

.field .dropdown .menu .item.selected {
    background: var(--color-gray-100);
    color: var(--color-gray-900);
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.171875rem;
}

.field .dropdown .menu .item.disabled {
    cursor: default;
    background: var(--color-gray-50);
    color: var(--color-gray-400);
}

div.field > div.dropdown_popup {
    border: none;
    height: unset;
}

div.field > div.dropdown_popup > input, div.field > div.dropdown_popup > div.divider.text, div.field > div.dropdown_popup > div.icon {
    display: none;
}

div.field > div.dropdown_popup > div.menu {
    position: relative;
    width: 19.125rem;
    margin: -0.25rem -0.875rem -0.125rem -0.875rem;
}

.dropdown__item__twoline {
    flex-direction: column;
}

.dropdown__item__twoline .text--xs-regular {
    display: block;
    color: var(--color-gray-500);
}

/*  colorpicker ------------------------------------------------------------------------------------------------------ */

div.ui.input input[type='color'] {
    width: 8.6875rem;
    height: 2.375rem;
    padding: 0.5625rem 0.8125rem;
    border-radius: 0.375rem;
    border: 1px solid var(--color-gray-300);
    background: var(--color-white);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
    appearance: none;
    -webkit-appearance: none;
}

div.ui.input input[type='color']::-webkit-color-swatch, div.ui.input input[type='color']::-webkit-color-swatch-wrapper {
    margin: 0;
    padding: 0;
    border: 0.0625rem solid var(--color-gray-200);
}



/*  datepicker ------------------------------------------------------------------------------------------------------ */

.field .react-datepicker-wrapper, .field .react-datepicker-wrapper~.field__feedback {
    max-width: 16.55rem;
}

/*  select_radio ------------------------------------------------------------------------------------------------------ */

.select_radio__container {
    position: relative;
    display: flex;
    flex-direction: column;
    border: 1px solid var(--color-gray-300);
    border-radius: 0.375rem;
    box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
    color: var(--color-gray-900);
    height: 16.875rem;
    padding: 0.5rem;
    width: 30.75rem;
}

.select_radio__container.empty {
    background-color: var(--color-gray-100);
}

.select_radio__container input {
    flex-grow: 1;
}

.select_radio__container ::-webkit-scrollbar {
    width: 0.8125rem;
}
.select_radio__container ::-webkit-scrollbar-track {
    border-radius: 0.375rem;
    background-color: var(--color-gray-200);
}

.select_radio__container ::-webkit-scrollbar-thumb {
    background: var(--color-gray-400);
    border: 0.203125rem solid var(--color-gray-200);
    border-radius: 0.625rem;
}

.select_radio__container .field {
    flex-grow: unset;
    margin-bottom: 0.5rem;
}

.select_radio__container .icon__search {
    align-items: center;
}

.select_radio__container .select_radio__search input {
    max-width: unset;
    padding-right: 2rem !important;
}

.select_radio__container .select_radio__results {
    overflow: auto;
    flex-direction: column;
}

.select_radio__container .select_radio__results .select_radio__item {
    padding: 0.75rem 0;
    margin: 0 0.375rem;
    position: relative;
}

.select_radio__container .select_radio__results .select_radio__item:not(:last-child) {
    border-bottom: 0.063rem solid var(--color-gray-200);
}

.select_radio__item .ui.checkbox{
    margin-top: 1px;
}

.select_radio__container .ui.checkbox input[type=checkbox] {
    width: unset;
    height: unset;
    margin: revert;
}
.select_radio__container .ui.radio.checkbox {
    margin: 0.125rem 0.5rem 0 0.125rem !important;
}

.select_radio__container .ui.radio.checkbox label {
    padding-left: 1.25rem !important;
}

.select_radio__container .ui.radio.checkbox label:before {
    position: absolute;
    top: 0.0625rem;
    left: 0;
    width: 0.8125rem;
    height: 0.8125rem;
    border: 0.0625rem solid var(--color-gray-300);
    border-radius: 500rem;
    content: '';
    transition: border .1s ease,opacity .1s ease,transform .1s ease,box-shadow .1s ease;
}
.select_radio__container .ui.radio.checkbox input:checked~label:before {
    background-color: var(--color-gray-700);
    border-color: var(--color-gray-700);
}

.select_radio__container .ui.radio.checkbox.disabled input:checked~label:before {
    background-color: var(--color-gray-300);
    border-color: var(--color-gray-300);
}
.select_radio__container .ui.radio.checkbox label:after {
    position: absolute;
    top: 0.0625rem;
    left: 0;
    width: 0.9375rem;
    height: 0.9375rem;
    border-radius: 500rem;
    content: '';
    transition: border .1s ease,opacity .1s ease,transform .1s ease,box-shadow .1s ease;
}
.select_radio__container .ui.radio.checkbox input:checked~label:after,
.select_radio__container .ui.radio.checkbox.disabled input:checked~label:after {
    background-color: var(--color-white);
    transform: scale(.35);
}

.select_radio__container .ui.radio.checkbox input:disabled~label:after {
    background-color: var(--color-gray-300);
    transform: scale(.85);
}
.select_radio__container .ui.checkbox:not(.radio) input:disabled~label:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 1rem;
    height: 1rem;
    opacity: 1;
    border: 0.0625rem solid var(--color-gray-300);
    border-radius: 0.25rem;
    background-color: var(--color-gray-300);
    content: '';
}

.select_radio__item.disabled {
    background: var(--color-gray-50);
}

.select_radio__item__twoline {
    flex-direction: column;
}

.select_radio__item__twoline .text--xs-regular {
    display: block;
    color: var(--color-gray-500);
}

.select_radio__item__twoline .text--xs-regular.color--green-500 {
    color: var(--color-green-500);
}



/*  MEDIA QUERIES =================================================================================================== */

/*880px*/
@media only screen and (max-width: 55rem) {

    .detailsgroup {
        flex-direction: column;
        align-items: flex-start;
    }

    .detailsgroup__label {
        color: var(--color-gray-500);
        width: 9rem;
        margin-bottom: 0.25rem;
    }

    .detailsgroup__value {
        width: 100%;
    }

    .detailsgroup__subgroup {
        margin-left: 0;
    }

    .select_radio__container {
        padding: 0.5rem;
        min-width: 18rem;
    }

}

/*568px*/
@media only screen and (max-width: 35.5rem) {

    .field .select_radio__container {
        width: 100%;
    }

    .select_radio__container {
        max-width: 100%;
    }

    .detailsgroup .field {
        width: 100%;
        margin-top: 0;
    }

}

/*500px*/
@media only screen and (max-width: 31.25rem) {

    .field input,
    .field select,
    .field .dropdown,
    .field .select_radio__container {
        width: 100%;
    }

}

@media screen and (-webkit-min-device-pixel-ratio:0) {
    select:focus,
    textarea:focus,
    input:focus {
        font-size: 16px;
        background: var(--color-white);
    }
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
    input:focus-visible {
        outline: 0.188rem solid var(--color-yellow-400);
        outline-offset: 0;
    }
}