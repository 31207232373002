#mdl_batch_credits #cmp_credit_activity_list {
    max-width: 30.75rem;
}

#mdl_batch_credits .item_credits {
    right: 4.5rem;
}

#mdl_batch_credits .category_summary {
    right: 5rem;
}