#mdl_prequalifiers_details .prequalifier_text,
#mdl_prequalifiers_details .prequalifier {
    flex-direction: column;
}

#mdl_prequalifiers_details .description {
    color: var(--color-gray-500);
    margin-bottom: 0.75rem;
}

#mdl_prequalifiers_details .prequalifier_text .badge {
    margin: 0.25rem 0 0.75rem 0;
}

#mdl_prequalifiers_details .cell__icon:has(.prequalifiers_icon) {
    vertical-align: top;
    padding-top: 0.75rem;
}

#mdl_prequalifiers_details .prequalifier_credential_detail {
    margin-bottom: 0.25rem;
    align-items: center;
}

#mdl_prequalifiers_details .prequalifier_credential_detail button {
    margin-right: 0.25rem;
}

#mdl_prequalifiers_details .credential_name {
    max-width: 18rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

#mdl_prequalifiers_details .prequalifier_credential_detail:last-of-type {
    margin-bottom: 0;
}

#mdl_prequalifiers_details .modal__content {
    padding-top: 0;
    padding-bottom: 0;
}

#mdl_prequalifiers_details tbody tr:last-of-type {
    border-bottom: 0;
}

#mdl_prequalifiers_details .table__headercell_text {
    cursor: default;
}

#mdl_prequalifiers_details tbody tr:has(.prequalifier) {
    cursor: default !important;
}

@media only screen and (max-width: 70rem) {

    #mdl_prequalifiers_details .prequalifier_credential_detail  {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 0.5rem !important;
    }

    #mdl_prequalifiers_details .quaternary,
    #mdl_prequalifiers_details .status,
    #mdl_prequalifiers_details .description {
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 1rem;
    }

    #mdl_prequalifiers_details .badge {
        font-weight: 500;
        font-size: 0.75rem;
        line-height: 1rem;
    }
}