
/*  table controls -------------------------------------------------------------------------------------------------- */

.table__options {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
}

.table__options .field input:not([type=checkbox]) {
    margin-top: 0;
}

.table__controls {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.table__controls--top {
    margin-bottom: 2rem;
}

.table__controls--bottom {
    margin-top: 2rem;
}

.table__controls--mobile {
    display: none;
}


/*  table ----------------------------------------------------------------------------------------------------------- */

.table__headercell {
    display: flex;
    position: relative;
    height: 100%;
    min-height: 2.5rem;
}

.table__headercell_text {
    flex-grow: 1;
    padding-left: 1.5rem;
    padding-right: 0.75rem;
    align-items: center;
    cursor: pointer;
}

.table__filter {
    cursor: pointer;
    padding: 0 0.5rem;
    height: 100%;
    align-items: center;
}

.table__filter button {
    border: none;
    box-shadow: none;
    background-color: var(--color-gray-50);
    height: 2.25rem;
    padding: 0 0.75rem 0 0.75rem;
}

.table__resizer {
    width: 0.375rem;
    border-right: 1px solid var(--color-gray-200);
    z-index: 1;
    touch-action: none;
    cursor: ew-resize;
}

thead th:last-of-type .table__headercell .table__resizer {
    border-right: none;
}

.lock-first-column {
    position: sticky;
    left: 0;
    top: 0;
    z-index: 2;
}

th.lock-first-column {
    background-color: var(--color-gray-50);
}

td.lock-first-column {
    background-clip: padding-box;
    background-color: var(--color-white);
}

tbody tr:hover > td.lock-first-column {
    background-color: var(--color-gray-100);
}


/*  table cells ----------------------------------------------------------------------------------------------------- */

th:focus-visible {
    outline-offset: -0.188rem;
}

th .icon__filter {
    position: relative;
    width: 0.75rem !important;
    height: 0.5rem !important;
    color: var(--color-gray-500);
}

th .icon__filter svg {
    width: 12px;
    height: 8px;
}

th.cell__icon .table__headercell_text {
    padding-left: 1rem;
    padding-right: 1rem;
}

.cell--center {
    margin-left: 0;
    justify-content: center;
}

.cell--center .svg {
    margin: 0 auto;
}

.cell--left--right {
    display: flex;
    height: inherit;
    align-items: center;
    column-gap: 1rem;
    width: inherit !important;
}

.cell--left--right__left {
    width: 50%;
    justify-content: center;
}

.cell--left--right__right {
    width: 50%;
    justify-content: center;
}

.cell--left--right__right .svg {
    height: 1.125rem !important;
    width: 1.125rem !important;
}

.cell__icon {
    width: 4rem;
    padding: 0;
}

.cell__icon .svg {
    height: 1.5rem;
    width: 1.5rem;
}

.cell__icon__chevron {
    align-self: center;
    margin-left: auto;
    cursor: pointer;
}

.cell__icon__chevron--right svg {
    padding-left: 0.5rem;
    height: 0.75rem;
    width: 0.475rem;
}

.cell__icon--right {
    display: flex;
    height: inherit;
    align-items: center;
}

.cell__icon--left--right {
    display: flex;
    height: inherit;
    align-items: center;
    padding-left: 1rem !important;
}

.cell__icon--left--right .svg {
    height: 1.5rem;
    width: 1.5rem;
}

.cell__icon--left--right button .svg {
    height: 1.125rem;
    width: 1.125rem;
}

.cell__icon--right--close {
    display: flex;
    height: inherit;
    align-items: center;
}

.cell__icon--left--right button,
.cell__icon--right button {
    width: auto !important;
}

.cell__icon--left--right button:has(svg),
.cell__icon--right button:has(svg) {
    padding: 0.75rem 1rem 0.75rem 1rem !important;
    margin: 0 0 0 auto;
    width: auto !important;
}

.cell__icon--right .svg {
    height: 1.125rem;
    width: 1.125rem;
    cursor: pointer;
    margin-left: auto;
}

.cell__text--2rows {
    flex-direction: column;
    word-wrap: break-word;
    white-space: normal;
    overflow: hidden;
    max-height: 3.8125rem;
}

.cell__text--2rows .text--xs-regular {
    color: var(--color-gray-500);
}

.cell__datetime {
    white-space: nowrap;
}

.cell__detail {
    flex-flow: row wrap;
    column-gap: 0.75rem;
    row-gap: 0.5rem;
    width: 100%;
}

.cell__detail__item {
    flex-direction: row;
    column-gap: 0.25rem;
    white-space: nowrap;
    align-items: center;
}

.cell__detail__item .tertiary {
    height: unset;
}

td .tertiary:focus-visible {
    outline-offset: -0.188rem;
}

td .tertiary {
    line-height: 1rem !important;
    text-align: left;
    height: unset;
}



/*  MEDIA QUERIES =================================================================================================== */

@media only screen and (max-width: 70rem) {

    .table__options {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: flex-start;
    }

    .table__controls {
        flex-direction: row-reverse;
    }

    .table__controls--desktop {
        display: none;
    }

    .table__controls--mobile {
        display: flex;
    }
}

@media only screen and (max-width: 53.125rem) {

    .table__headercell_text {
        padding-left: 1.25rem;
        padding-right: 1.25rem;
    }

}

@media only screen and (max-width: 41rem) {

    .table__headercell_text {
        padding-left: 0.75rem;
        padding-right: 0.75rem;
    }

}